import React from "react"


import FindHouseButton from "../findHouseButton"
import KoshevickiyIntroduction from "../KoshevickiyIntroduction.js";

const invitations = [
    {
        text: "Узнайте больше о посёлке и осмотрите окрестности",
        id: "1",
    },
    {
        text: "Узнайте больше о посёлке и осмотрите окрестности с официальным представителем",
        id: "2",
    },
    {
        text: "Узнайте больше о посёлке с официальным представителем",
        id: "3",
    },
    {
        text: "Просмотрите актуальные предложения - участки, дома на продажу или в аренду. Готовы дать консультацию, рассказать об особенностях посёлка.",
        id: "4",
    }
]

const quote =
    <p className="quote">
        Мы всегда получаем информацию из первых рук и сопровождаем сделки под ключ. Доступ к закрытым продажам возможен только после личного знакомства
    </p>;

const SalesSection = () => {
    
    return (
        <section id="salesSection">
            <h2 className="hidden">Офис продаж</h2>
            <p id="invitation">{invitations[3].text}</p>
            <FindHouseButton CTA="Заявка на подбор"/>
            <KoshevickiyIntroduction quote={quote} />
        </section>
    );
}

export default SalesSection;