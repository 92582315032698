import React, { useContext } from "react"
import SanityImage from "gatsby-plugin-sanity-image" 
import { MainContext } from "../../helpers/MainContext";
import { areaMeasureNaming, spacefyNumber } from "../../helpers/littleHelpers"
import LinkOrScript from "./LinkOrScript";

export function MansionOnSaleCard({ data }) {
  const {setSecretOfInterest} = useContext(MainContext);

  return (
    <>
      {data.map(({ node }) => (
        <div key={node._id} className={`${node?.secretObject === true ? "private": ""} estateObject`} index={node.orderPriority} >
          <LinkOrScript 
            to={node.slug.current} 
            secretObject={node.secretObject}
            objectID={node.objectID}
            name={node.name}
            secretSetterFunction={setSecretOfInterest}
          >
            <div className="object-card-image-wrapper">
              <SanityImage
                {...node.imgFront}
                alt=""
                className="object-card-img"
                width={400}
                height={225}
                style={{
                  aspectRatio: "400/225"
                }}
                config={{
                  quality: 85,
                  fit: "min",
                  auto: "format",
                  crop: "entropy",
                }}
              />
            </div>
            <dl className="details">
              <div className="houseArea">
                <dt>площадь дома:</dt>
                <dd>{spacefyNumber(node.houseArea)}</dd>
              </div>
              <div className="siteArea">
                <dt>площадь участка:</dt>
                <dd>
                  {`${spacefyNumber(node.siteArea)} ${areaMeasureNaming(node.siteArea)}`}
                </dd>
              </div>
              <div className="houseBedrooms">
                <dt>количество спален:</dt>
                <dd>{node.houseBedrooms}</dd>
              </div>
              <div className="price">
                <dt>стоимость:</dt>
                <dd>
                  {
                    node.priceOnDemand === false ? 
                    `${node.currencyOfSell === "RUB" ? "₽" : node.currencyOfSell === "USD" ? "$" : "€"}  ${spacefyNumber(node.priceToSell)}`
                    :
                    "цена по запросу"
                  }
                </dd>
              </div>
            </dl>
            {node.rentPossible && (
              <span className="plusPossibility">
                {
                  node.priceOnDemand === false ? 
                  `возможна аренда ${node.currencyOfRent === "RUB" ? "₽" : node.currencyOfRent === "USD" ? "$" : "€"} ${spacefyNumber(node.priceToRent)}`
                  :
                  "возможна аренда"  
                }
              </span>
            )}
          </LinkOrScript>
        </div>
      ))}
    </>
  )
}
  
export const MansionOnSaleCardMemoized = React.memo(MansionOnSaleCard);