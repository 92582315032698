import { graphql, useStaticQuery } from "gatsby"

const ShortDataOnMansions = () => {
  const mansionsData = useStaticQuery(graphql`
    query MansionsQuery {
      allSanityMansion(
        sort: { fields: orderPriority, order: DESC }
      ) {
        edges {
          node {
            _id
            objectID
            secretObject
            name
            orderPriority
            houseArea 
            houseBedrooms
            siteArea
            siteAreaStatus
            priceOnDemand
            sellPossible
            priceToSell
            currencyOfSell
            rentPossible
            priceToRent
            rentOccupied
            currencyOfRent
            imgFront {
              ...ImageWithPreview
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)
  return mansionsData
}

export default ShortDataOnMansions
