import React, {useContext } from "react"
import PropTypes from "prop-types"
import { MainContext } from "../../helpers/MainContext"

import emblem from '../../images/emblem.svg'

const Hello = ({ helloSlogan }) => {
    const {setContactsVisibility, setLocationVisibility, setFilterObjectsBy} = useContext(MainContext);

    const showContactsClick = (e) => {
        e.preventDefault();
        setContactsVisibility(true);

    }
    const showLocationClick = (e) => {
        e.preventDefault();
        setLocationVisibility(true);
    }

    const showMansionsOnSale = (e) => {
        e.preventDefault();
        const objectControls = document.getElementById("hat-controls");
        const objectControlsPositionY = objectControls.getBoundingClientRect().top + window.scrollY - objectControls.offsetTop;
        setFilterObjectsBy("onSale");
        window.scrollTo(0, objectControlsPositionY)
        
        document.getElementById("hat-controls").focus();
    }

    const showMansionsOnRent = (e) => {
        e.preventDefault();
        const objectControls = document.getElementById("hat-controls");
        const objectControlsPositionY = objectControls.getBoundingClientRect().top + window.scrollY - objectControls.offsetTop;
        setFilterObjectsBy("onRent");
        window.scrollTo(0, objectControlsPositionY)
        document.getElementById("hat-controls").focus();
    }

    const showLandObjects = (e) => {
        e.preventDefault();
        const objectControls = document.getElementById("hat-controls");
        const objectControlsPositionY = objectControls.getBoundingClientRect().top + window.scrollY - objectControls.offsetTop;
        setFilterObjectsBy("onLand");
        window.scrollTo(0, objectControlsPositionY)
        document.getElementById("hat-controls").focus();
    }

    const blessReact = () => {
        console.log("this.steakholder")
        // to be replaced on terminal-like string
    }

    return (
        <section className='hello'>
            <div id="intro">
                <img id='emblem' src={emblem} alt='Эмблема территории «Ландшафт»' />
                <h1 id='title'><span>Коттеджный посёлок</span> «Ландшафт»</h1>
                <p id="sloganFirst" onSelect={blessReact}>{helloSlogan}</p>
            </div>
            <nav id='shortcuts'>
                <button className="secondaryLink" onClick={showLocationClick}>Местоположение</button>
                <button className="secondaryLink anchor" onClick={showMansionsOnSale}>Дома в продаже</button>
                <button className="secondaryLink anchor" onClick={showMansionsOnRent}>Дома в аренду</button>
                <div className="lineBreaker"><br/></div>
                <button className="secondaryLink anchor" onClick={showLandObjects}>Участки в продаже</button>
                <button className="secondaryLink" onClick={showContactsClick}>Контакты</button>
            </nav>
        </section>
    )
}

Hello.propTypes = {
    helloSlogan: PropTypes.object,
  }
  
Hello.defaultProps = {
    helloSlogan: <>Особый взгляд на <br/> загородную жизнь</>,
  }

export default Hello