import React from "react"
import { Link } from "gatsby"

const LinkOrScript = ({to, secretObject, objectID, name, secretSetterFunction, children}) => {

    const notifyOnSecretInterest = (id, name, setterFunction) => {
          setterFunction({
            objectID: id,
            name: name
          })

      }

    const handleClickOnLink = (secretObject, objectID, name) => {

        if (secretObject === true) {
          let targetY;
          const isSmallScreen = window.matchMedia('(max-width: 930px)');
    
          if (isSmallScreen.matches === true) {
            let targetObject = document.getElementById("invitation");
            let marginOffcet = window.getComputedStyle(targetObject).marginBottom.replace(/px/, "");
            targetY = targetObject.offsetTop + document.getElementById("salesSection").offsetTop - marginOffcet;
        } if (isSmallScreen.matches === false) {
            targetY = document.getElementById("salesSection").offsetTop;
          }
    
          window.scrollTo(0, targetY);
          notifyOnSecretInterest(objectID, name, secretSetterFunction);
        }
      }
    
      const handleKeypress = (e, secretObject, objectID, name) => {
        if ( e.keyCode === 13 ) {
          handleClickOnLink(secretObject, objectID, name)
        }
      }
      
    if (secretObject === true) {
        return <>
            <div 
                tabIndex="0" 
                onClick={ () => {
                    handleClickOnLink(secretObject, objectID, name)
                    }}
                onKeyDown={(e) => {
                    handleKeypress(e, secretObject, objectID, name)
                    }}
            >
                { children }
            </div>
        </>
    } else {
        return <>
            <Link to={to}>
                { children }
            </Link>
        </>
    }
}

export default LinkOrScript;