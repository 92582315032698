import React from "react"
// import { GetExchangeRates } from "../../helpers/currencyConvertor"

import { MansionOnSaleCardMemoized } from "./mansionOnSaleCard"
import { MansionOnRentCardMemoized } from "./mansionOnRentCard"
import { LandOnSaleCardMemoized } from "./landOnSaleCard"



export default function ObjectsCards(props) {

  let noObjectsAvailableStyle = {
    textAlign: "center",
    width: "20ch",
    lineHeight: "1.2em",
    marginTop: "1.618em",
    marginBottom: "1.618em",
    fontSize: "1.081em",
    maxWidth: "40ch",
  }

  if (props.category === "onSale") {
    return (
      <>
        {
          props.data[0] ?
            <MansionOnSaleCardMemoized data={props.data} />
            :
            <>
              <h3 style={noObjectsAvailableStyle}>Сейчас домов в продаже нет</h3>
            </>
        }
      </>
    )
  }
  
  if (props.category === "onRent") {
    return (
      <>
        {
          props.data[0] ?
            <MansionOnRentCardMemoized data={props.data} />
            :
            <>
              <h3 style={noObjectsAvailableStyle}>В настоящий момент все дома сданы. Оставьте заявку и мы свяжемся с вами, как только появится подходящий для вас дом.</h3>
            </>
        }
      </>
    )
  }

  if (props.category === "onLand" ) {
    return (
      <>
        {
          props.data[0] ?
            <LandOnSaleCardMemoized data={props.data} />
            :
            <>
              <h3 style={noObjectsAvailableStyle}>Участков в продаже больше нет</h3>
            </>
        }
      </>
    )
  }
}
