import React, {useContext} from "react"

import { MainContext } from "../helpers/MainContext"

const FindHouseButton = ( {CTA = "Устроить просмотр"} ) => {
    const {setMainFormVisibility} = useContext(MainContext);

    const findHouseClick = (e) => {
        e.preventDefault();
        setMainFormVisibility(true);
    }

    return (
        <button className="button findHouse" onClick={findHouseClick}>{CTA}</button>
    );
}

export default FindHouseButton;