import React, { useContext } from "react";
import Modal from 'react-modal';
import { MainContext } from "../../helpers/MainContext"
import ExitModalButton from "../forms/ExitModalButton";

const ContactsModal = () => {
    const {contactsVisibility, setContactsVisibility, setLocationVisibility} = useContext(MainContext);

    const closeModal = () => {
        if (typeof(document.getElementsByClassName("imageGallery")[0]) != 'undefined' && document.getElementsByClassName("imageGallery")[0] != null) {
            document.getElementsByClassName("imageGallery")[0].style.visibility = "visible";
        }
        setContactsVisibility(false)
    }

    const showLocation = () => {
        setLocationVisibility(true);
        setTimeout(() => {setContactsVisibility(false)}, 400);
    }

    Modal.setAppElement(`#___gatsby`);

    return (
        <Modal 
        isOpen={contactsVisibility}
        onRequestClose={closeModal}
        className="Modal contacts"
        overlayClassName="Overlay"
        >
            <ExitModalButton closeFunction={closeModal} ariaLabel="Закрыть окно с контактами администрации" />
            <h2 className="hidden">Контакты администрации:</h2>
            <dl id="contactsList">
                <dt>Недвижимость</dt>
                <dd><a className="phoneLink" href="tel:79852328778">+7 (985) 232-87-78</a></dd>

                <dt>Безопасность</dt>
                <dd><a className="phoneLink" href="tel:79104305555">+7 (910) 430-55-55</a></dd>

                <dt>Бухгалтерия</dt>
                <dd><a className="phoneLink" href="tel:74959257002">+7 (495) 925-70-02</a></dd>
            </dl>
            <a id="addressLink" href="https://yandex.ru/maps/-/CCUeuYX~dC" target="_blank" rel="noreferrer">
                Здание администрации | КПП №1
            </a>
            <button className="button showLocation" onClick={showLocation}>Расположение посёлка</button>
            <div style={{
                display: "block",
                height: "1.5rem",
                witdh: "100%",
                flexShrink: 0,
            }}></div>
        </Modal>
    );
}

export default ContactsModal;
