import React from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { areaMeasureNaming, spacefyNumber } from "../../helpers/littleHelpers"

export function landOnSaleCard ({ data }) {
  return (
    <>
      {data.map(({ node }) => (
        <div key={node._id} className={`estateObject land`} index={node.orderPriority} >
          <Link to={node.slug.current}>
          <SanityImage
            {...node.imgFront}
            alt=""
            className="object-card-img"
            width={400}
            height={225}
            config={{
              quality: 85,
              fit: "min",
              auto: "format",
              crop: "entropy",
            }}
          />
          <dl className="details">
            <div className="price">
              <dt>стоимость:</dt>
              <dd>
                {
                  node.priceOnDemand === false ? 
                  `${node.currencyOfSell === "RUB" ? "₽" : node.currencyOfSell === "USD" ? "$" : "€"} ${spacefyNumber(node.priceToSell)}`
                  :
                  "цена по запросу"
                }
              </dd>
            </div>
            <div className="siteArea">
              <dt>площадь участка:</dt>
              <dd>
                {`${spacefyNumber(node.siteArea)} ${areaMeasureNaming(node.siteArea)}`}
              </dd>
            </div>
            <div className="siteResources">
              <dt>коммуникации:</dt>
              <dd>{node.siteResources}</dd>
            </div>
            <div className="siteStatus">
              <dt>состояние участка:</dt>
              <dd>{node.siteStatus}</dd>
            </div>
          </dl>
          </Link>
        </div>
      ))}
    </>
  )
}
  
export const LandOnSaleCardMemoized = React.memo(landOnSaleCard);