import React, {useEffect, useRef} from "react";

const InnerControls = ({ nextTabFunction, showGalleryFunction, ariaLabelForNextTab, ariaLabelForPhoto, isPressed = false, resizeToggle}) => {
    const firstRender = useRef(true);

    const getAndSetBottomPosition = () => {
        let aboutContentElement
        let desiredBottomPosition

        window.setTimeout(() => {
            aboutContentElement = document.getElementById("aboutContent");
            if (!aboutContentElement) {    
                return null;
            }
            
            desiredBottomPosition = aboutContentElement.scrollHeight + aboutContentElement.offsetTop;

            if (desiredBottomPosition !== 0) {
                document.getElementById("aboutSectionInnerControls").style.setProperty("--desiredTopPositon", `${desiredBottomPosition}px`);
            } else {
                getAndSetBottomPosition();
            }

        }, 100)
    }

    useEffect(() => {
        if (firstRender.current === true && isPressed === true) {
            getAndSetBottomPosition();
            firstRender.current = false;
        } if (firstRender.current === false && isPressed === true) {
            getAndSetBottomPosition();
            window.addEventListener('resize', getAndSetBottomPosition);
        } if (isPressed === false) {
            window.removeEventListener('resize', getAndSetBottomPosition);
        }
    }, [isPressed, resizeToggle])

    return (
        <div id="aboutSectionInnerControls" key="aboutSectionInnerControls" className={isPressed ? "shown" : ""}>
            <div className="aboutSectionButtonsWrapper">
                <button 
                    aria-label={ariaLabelForNextTab}
                    className="showNextTab"
                    onClick={(e) => {
                        e.preventDefault();
                        nextTabFunction();
                    }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" className="icon-next-tab">
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M7.9,31.1h19.2c1.1,0,2.1-0.4,2.8-1.2h0c0.7-0.7,1.2-1.7,1.2-2.8V7.9c0-1.1-0.4-2.1-1.2-2.8l0,0
                            c-0.7-0.7-1.7-1.2-2.8-1.2H7.9c-1.1,0-2.1,0.4-2.8,1.2v0C4.4,5.8,3.9,6.8,3.9,7.9v19.2c0,1.1,0.4,2.1,1.2,2.8
                            C5.8,30.6,6.8,31.1,7.9,31.1L7.9,31.1z M15.8,23.8c-0.2,0.5-0.8,0.7-1.3,0.4c-0.5-0.2-0.7-0.8-0.4-1.3c0.6-1.3,1.6-2.6,2.9-3.7
                            c0.7-0.6,1.4-1.2,2.2-1.7c-0.8-0.5-1.6-1.1-2.2-1.7c-1.2-1.1-2.2-2.4-2.9-3.7c-0.2-0.5,0-1.1,0.4-1.3c0.5-0.2,1.1,0,1.3,0.4
                            c0.5,1.1,1.4,2.2,2.4,3.1c1,0.9,2.2,1.8,3.4,2.3c0.2,0.1,0.4,0.3,0.5,0.5c0.2,0.5,0,1.1-0.5,1.3c-1.2,0.5-2.4,1.3-3.4,2.3
                            C17.2,21.7,16.3,22.8,15.8,23.8L15.8,23.8z M27.1,33.1H7.9c-1.6,0-3.1-0.7-4.2-1.7c-1.1-1.1-1.7-2.6-1.7-4.2V7.9
                            c0-1.6,0.7-3.1,1.7-4.2c1.1-1.1,2.6-1.7,4.2-1.7h19.2c1.6,0,3.1,0.7,4.2,1.7l0,0c1.1,1.1,1.7,2.6,1.7,4.2v19.2
                            c0,1.6-0.7,3.1-1.7,4.2C30.2,32.4,28.7,33.1,27.1,33.1z"/>
                    </svg>
                </button>
                <button 
                    aria-label={ariaLabelForPhoto}
                    className="showPhoto"
                    onClick={(e) => {
                        e.preventDefault();
                        showGalleryFunction();
                    }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" className="icon-photo">
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M10.2,12.1h3v-1.5c0-1.3,1.1-2.3,2.3-2.3h4c1.3,0,2.3,1.1,2.3,2.3l0,1.5h3c1.7,0,3.1,1.4,3.1,3.1V23
                            c0,1.7-1.4,3.1-3.1,3.1H10.2c-1.7,0-3.1-1.4-3.1-3.1v-7.8C7.2,13.5,8.5,12.1,10.2,12.1L10.2,12.1z M7.9,31.1h19.2
                            c1.1,0,2.1-0.4,2.8-1.2h0c0.7-0.7,1.2-1.7,1.2-2.8V7.9c0-1.1-0.4-2.1-1.2-2.8l0,0c-0.7-0.7-1.7-1.2-2.8-1.2H7.9
                            c-1.1,0-2.1,0.4-2.8,1.2v0C4.4,5.8,3.9,6.8,3.9,7.9v19.2c0,1.1,0.4,2.1,1.2,2.8C5.8,30.6,6.8,31.1,7.9,31.1L7.9,31.1z M27.1,33.1
                            c1.6,0,3.1-0.7,4.2-1.7c1.1-1.1,1.7-2.6,1.7-4.2V7.9c0-1.6-0.7-3.1-1.7-4.2l0,0c-1.1-1.1-2.6-1.7-4.2-1.7H7.9
                            c-1.6,0-3.1,0.7-4.2,1.7C2.6,4.8,1.9,6.3,1.9,7.9v19.2c0,1.6,0.7,3.1,1.7,4.2c1.1,1.1,2.6,1.7,4.2,1.7H27.1L27.1,33.1z M23.8,15.1
                            c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9C24.7,15.5,24.3,15.1,23.8,15.1L23.8,15.1z M17.5,15
                            c-1.1,0-2.1,0.5-2.8,1.2l0,0c-0.7,0.7-1.2,1.7-1.2,2.8c0,1.1,0.5,2.1,1.2,2.8l0,0c0.7,0.7,1.7,1.2,2.8,1.2c1.1,0,2.1-0.5,2.8-1.2
                            c0.7-0.7,1.2-1.7,1.2-2.8s-0.5-2.1-1.2-2.8C19.6,15.5,18.6,15,17.5,15L17.5,15z M18.9,17.6c-0.4-0.4-0.9-0.6-1.4-0.6
                            c-0.6,0-1.1,0.2-1.4,0.6l0,0c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.6,0.2,1.1,0.6,1.4l0,0c0.4,0.4,0.9,0.6,1.4,0.6c0.6,0,1.1-0.2,1.4-0.6
                            c0.4-0.4,0.6-0.9,0.6-1.4S19.3,18,18.9,17.6L18.9,17.6z M15.2,12.1h4.6l0-1.5c0-0.2-0.2-0.3-0.3-0.3h-4c-0.2,0-0.3,0.1-0.3,0.3V12.1
                            L15.2,12.1z M24.8,14.1H10.2c-0.6,0-1.1,0.5-1.1,1.1V23c0,0.6,0.5,1.1,1.1,1.1h14.5c0.6,0,1.1-0.5,1.1-1.1v-7.8
                            C25.8,14.6,25.4,14.1,24.8,14.1z"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default InnerControls;



