import React from "react"

import MansionsData from "./shortDataOnMansions"
import LandsData from "./shortDataOnLands"

import ObjectsCards from "./objectsCardsRendering";

export default function AvailableMansions ( props ) {

  const dataMansions = MansionsData();
  const dataLands = LandsData();
  const data = Object.assign(dataMansions, dataLands);

  const filterDataProperly = ( filter ) => {
    let filteredData;
    if (filter === "onSale") {
      filteredData = data.allSanityMansion.edges.filter(
        object => object.node.sellPossible === true
      );
      return filteredData;
    } if (filter === "onRent") {
      filteredData = data.allSanityMansion.edges.filter(
        object => object.node.rentPossible === true
        );
      return filteredData;
    } if (filter === "onLand") {
      return data.allSanityLand.edges;
    }
  }

  return <ObjectsCards key={props.filter} category={props.filter} data={filterDataProperly(props.filter)} />
}