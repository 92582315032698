import React from "react"

import Layout from "../components/layout"
import CriticalHead from "../components/CriticalHead"
import FormsModal from "../components/forms/FormsModal"
import ContactsModal from "../components/contacts/ContactsModal"
import LocationModal from "../components/location/LocationModal"
import ObjectsRentModal from "../components/forms/ObjectsRentModal"

import Hello from "../components/hello/Hello.js"
import About from "../components/about/About.js"
import Objects from "../components/objects/Objects.js"
import SalesSection from "../components/sales-section/SalesSection"

const IndexPage = () => {

  return (
    <Layout>
      <CriticalHead title="Официальный сайт" lang="ru-RU"/>
      <FormsModal />
      <LocationModal />
      <ContactsModal />
      <ObjectsRentModal />
      <Hello />
      <About />
      <Objects />
      <SalesSection />
    </Layout>
  )
}

export default IndexPage
