import React, { useContext } from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { areaMeasureNaming, spacefyNumber, notifyOnSecretInterest } from "../../helpers/littleHelpers"
import {MainContext} from "../../helpers/MainContext"
import LinkOrScript from "./LinkOrScript";

export function MansionOnRentCard({ data }) {
  const { setSecretOfInterest, setObjectRentFormVisibility, setRentObjectOfInterest } = useContext(MainContext); 

  const handleClickOnRentInform = (objectID, name) => {
    setRentObjectOfInterest({objectID: objectID, name: name})
    setObjectRentFormVisibility(true);
  }

  return (
    <>
      {data.map(({ node }) => (
        <div key={node._id} className="estateObjectWrapper">
          { node.rentOccupied === true &&
            <button className="button notify-for-rent" onClick={() => { handleClickOnRentInform(node.objectID, node.name) } }>Сообщить об освобождении</button>
          }
          <div className={`${node?.secretObject === true ? "private": ""} estateObject ${node.rentOccupied === true ? "occupied" : "" }`} index={node.orderPriority} >
            {/* <Link to={node?.secretObject === true ? "#salesSection": node.slug.current} onClick={() => { notifyOnSecretInterest(node.secretObject, node.objectID, node.name, setSecretOfInterest) } }> */}
              <LinkOrScript 
                to={node.slug.current} 
                secretObject={node.secretObject}
                objectID={node.objectID}
                name={node.name}
                secretSetterFunction={setSecretOfInterest}
              >
              <div className="object-card-image-wrapper">
                <SanityImage
                  {...node.imgFront}
                  alt=""
                  className={`object-card-img ${node.rentOccupied === true ? "occupied" : "" }`}
                  width={400}
                  height={225}
                  config={{
                    quality: 85,
                    fit: "min",
                    auto: "format",
                    crop: "entropy",
                  }}
                />
              </div>
              <dl className="details">
                <div className="houseArea">
                  <dt>площадь дома:</dt>
                  <dd>{spacefyNumber(node.houseArea)}</dd>
                </div>
                <div className="siteArea">
                  <dt>площадь участка:</dt>
                  <dd>{`${spacefyNumber(node.siteArea)} ${areaMeasureNaming(node.siteArea)}`}</dd>
                </div>
                <div className="houseBedrooms">
                  <dt>количество спален:</dt>
                  <dd>{node.houseBedrooms}</dd>
                </div>
                <div className="price">
                  <dt>стоимость:</dt>
                  <dd>
                    {
                      node.priceOnDemand === false ? 
                      `${node.currencyOfRent === "RUB" ? "₽" : node.currencyOfRent === "USD" ? "$" : "€"}  ${spacefyNumber(node.priceToRent)}`
                      :
                      "цена по запросу"
                    }
                  </dd>
                </div>
              </dl>
              {node.sellPossible && (
                <span className="plusPossibility">
                  {
                    node.priceOnDemand === false ? 
                    `возможна продажа ${node.currencyOfSell === "RUB" ? "₽" : node.currencyOfSell === "USD" ? "$" : "€"} ${spacefyNumber(node.priceToSell)}`
                    :
                    "возможна продажа"  
                  }
                </span>
              )}
            </LinkOrScript>
            {/* </Link> */}
          </div>
        </div>
      ))}
    </>
  )
}
  
export const MansionOnRentCardMemoized = React.memo(MansionOnRentCard);