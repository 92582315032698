import React, { useState, useRef, useEffect, cloneElement } from "react"
import AboutGbi from "./aboutGbi"
import { useAsyncReference, getAndSetCustomVh } from "../../helpers/littleHelpers"
import { Tabs, useTabState, Panel } from "@bumaga/tabs"
import { motion, AnimatePresence } from 'framer-motion'
import FocusLock from 'react-focus-lock'; 
import ExitModalButton from '../forms/ExitModalButton'
import InnerControls from '../about/innerControls'

import kpp1svg from "../../images/kpp1.svg"
import kpp3svg from "../../images/kpp3.svg"

import Photosets from "./Photosets";
import { GetAllPhotos } from "./photosetsData";

const cn = (...args) => args.filter(Boolean).join(" ")

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState()

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick} tabIndex={isActive ? -1 : 0}>
      {children}
    </button>
  )
}

const PanelList = ({ state, isPressed, children }) => {
  const panelRef = useRef()
  const [activeIndex] = state
  const [height, setHeight] = useState(429)

  useEffect(() => {
    panelRef.current && setHeight(panelRef.current.offsetHeight)
  }, [activeIndex, isPressed])


  return (
      <motion.div animate={{ height }}>
      <AnimatePresence initial={false}>
        <motion.div
          ref={panelRef}
          className='tabsContent'
          key={activeIndex}
          initial={{ opacity: 0, x: "-2rem"}}
          animate={{ opacity: 0.9, x: 0, z:1, transition: {delay: 0.4, ease: 'easeInOut', duration: 0.4}
          }}
          exit={{ opacity: 0, x: "2rem", transition: { ease: 'easeInOut', duration: 0.35 } }}
        >
          {cloneElement(children[activeIndex], { active: true })}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

const About = () => {
  const [isPressed, setPressed] = useAsyncReference(false);
  const [photoData, setPhotoData] = useAsyncReference(undefined);
  const [index, _setIndex] = useState(0);
  const [photoGalleryVisible, setPhotoGalleryVisible] = useState(false);
  const [resizeToggle, setResizeToggle] = useState(true);
  const indexStateRef = useRef(index);

  useEffect(() => {
    if (isPressed.current === true) {
      document.getElementsByClassName("hello")[0].style.opacity = 0;
      document.getElementsByClassName("divider-ornament slideUnder")[0].style.opacity = 0;
    } if (isPressed.current === false) {
      document.getElementsByClassName("hello")[0].style.opacity = 1;
      document.getElementsByClassName("divider-ornament slideUnder")[0].style.opacity = 1;
    }
  }, [isPressed.current])

  if (photoData.current === undefined && isPressed.current === true ) {
    let data = GetAllPhotos();
    setPhotoData(data);
  }

  const setIndex = data => {
    indexStateRef.current = data;
    _setIndex(data);
  }

  const debug = (event) => {
    /*
    // for a case, someone will scroll page after clicking on the "button more",
     to avoid being scroll locked in a wrong place
    */

    getAndSetCustomVh();

    if (isPressed.current === true) {

      window.setTimeout(() => {
  
        if (Math.abs(window.pageYOffset - document.getElementById("aboutSection").offsetTop) > 1) {

          window.scroll(0, document.getElementById("aboutSection").offsetTop);

          setResizeToggle(!resizeToggle);

          window.setTimeout(() => {

            if (Math.abs(window.pageYOffset - document.getElementById("aboutSection").offsetTop) > 1) {
              debug();
            }

          }, 1000);

        }
  
      }, 10)
      
    } else {
      window.removeEventListener('resize', debug);
    }
  }

  const lockPage = () => {

    if (isPressed.current === true) {
      document.body.classList.add("stop-scrolling");
    }

    debug();

  }

  const exitAbout = () => {

    document.body.classList.remove("stop-scrolling");
    document.getElementById("aboutSectionInnerControls").classList.remove("shown");
    setPhotoGalleryVisible(false);

    getAndSetCustomVh();
    setPressed(false);

    window.removeEventListener("keydown", handleEscDown);

    window.setTimeout(() => {
      if (
        document.body.classList.contains("stop-scrolling") 
        || 
        document.getElementById("aboutSectionInnerControls").classList.contains("shown") 
      ) {
        exitAbout();
      }
    }, 100);
  }

  const handleEscDown = (event) => {
    if (event.code === 'Escape' || event.code === 'Backspace') {
      exitAbout();
    }

  }

  const handleClickOnButtonMore = (e) => {
    e.preventDefault();
    setPressed(true);
    getAndSetCustomVh();

    window.setTimeout(() => {
      lockPage();
      document.getElementById("aboutContent").style.opacity = 1;
    }, 160);
    
    window.addEventListener('resize', debug);
    window.addEventListener('keydown', handleEscDown);
  }

  const nextTab = () => {
    if (indexStateRef.current <= 1) {
      setIndex(indexStateRef.current + 1);
    } else {
      setIndex(0);
    }
  }

  const showGallery = () => {
    setPhotoGalleryVisible(true);
  }

  return (
    <>
      <div className="divider-ornament slideUnder"></div>
      <FocusLock 
        disabled={!isPressed.current}
        returnFocus={true}>
        <AboutGbi>
        <section className={isPressed.current === false ? "about" : "about full"} >
          <div className={isPressed.current === false ? "glass" : `glass full ${photoGalleryVisible ? "blocked" : ""}`} >
            <h2 className="hidden">Описание коттеджного посёлка</h2>
            <div className={isPressed.current === false ? "aboutIntro" : "aboutIntroHidden"} >
              <p>
                «Ландшафт», расположенный в 11 км от мегаполиса, соединил в себе инфраструктуру и природу. Входит в тройку лучших поселков Рублёвки и заслужил столь высокую оценку. Комфорт и приватность здесь ставится во главу угла.
              </p>
              <button className="button more" onClick={handleClickOnButtonMore}>О посёлке</button>
            </div>
            <motion.div id="aboutContent" className={isPressed.current === false ? "" : "shown"}>
              <Tabs state={[index, setIndex]} >
                <ExitModalButton closeFunction={exitAbout} ariaLabel="Закрыть окно подробностей о жизни посёлка" addSVGClassName="aboutCloseSVG" />
                <nav className="tabsList">
                  <Tab>Местоположение</Tab>
                  <Tab>О посёлке</Tab>
                  <Tab>Инфраструктура</Tab>
                  {/* <Tab>Ресурсы</Tab> */}
                  {/* <Tab>Фотографии</Tab> */}
                </nav>
                <PanelList state={[index, setIndex]} isPressed={isPressed.current}>
                  <Panel>
                    <ul key="locationContent">
                      <li>Московская область, город Одинцово, <br/> деревня Жуковка, территория Ландшафт</li>
                      <li>Расстояние от МКАД - 11 км</li>
                      <li>
                        <p id="links-label">Как доехать:</p>
                        <div id="kpp-links-container">
                          <a href="https://maps.yandex.ru/?rtext=~55.727050,37.236009&rtt=mt" target="_blank" rel="noreferrer">
                            <img src={kpp1svg} alt="" />
                          </a>
                          <a href="https://maps.yandex.ru/?rtext=~55.724535,37.213054&rtt=mt" target="_blank" rel="noreferrer">
                            <img src={kpp3svg} alt="" />
                          </a>
                        </div>
                      </li>
                      {/* <li>
                        Посёлок окружён зелёной полосой и <wbr/>граничит с 
                        рекреационным комплексом «Подушкинский лес»
                      </li> */}
                    </ul>
                  </Panel>
                  <Panel>
                    <ul key="aboutVillage">
                      <li>Год основания - 1999 год</li>
                      <li>Площадь поселка - 76,6 Га</li>
                      <li>Количество домовладений - 109</li>
                      <li>Центральные коммуникации</li>
                      {/* <li>Пропускная система безопасности</li> */}
                      <li>Круглосуточное патрулирование</li>
                      <li>Собственная служба эксплуатации 24/7</li>
                    </ul>
                    {/* <ul key="serviceContent">
                      <li>
                        Вооружённая охрана в составе патрулей и на
                        контрольно-пропускных пунктах
                      </li>
                      <li>
                        Служба эксплуатации доступна для экстренного вызова круглосуточно
                      </li>
                      <li>
                        Служба хозяйственного обеспечения с собственным автопарком всегда готова к непогоде
                      </li>
                    </ul> */}
                  </Panel>
                  <Panel>
                    <ul key="infrastructureContent">
                      <li>Широкие дороги с тротуарами</li>
                      <li>Прогулочные зоны и детские площадки</li>
                      <li>Каскад прудов и сафари-парк</li>
                      <li>Медицинский центр и салон красоты</li>
                      <li>Кафе-кондитерская</li>
                      <li>Автомойка</li>
                    </ul>
                  </Panel>
                  {/* <Panel>
                    <ul key="resourcesContent">
                      <li>Собственная система водоснабжения</li>
                      <li>Центральная канализация</li>
                      <li>Разветвлённая газовая инфраструктура</li>
                      <li>Доступ к современным услугам связи и уверенный сотовый сигнал</li>
                    </ul>
                  </Panel> */}
                </PanelList>
              </Tabs>
            </motion.div>
            <InnerControls 
              key="innerControlsInAboutSection"
              nextTabFunction={nextTab}   
              showGalleryFunction={showGallery}   
              ariaLabelForNextTab="Следующая сфера посёлка" 
              ariaLabelForPhoto="Показать фотографии из посёлка и округи"
              isPressed={isPressed.current} 
              resizeToggle={resizeToggle}
            />
          </div>
          <Photosets visible={photoGalleryVisible} exitFunction={exitAbout} photoData={photoData.current} />
        </section>
        </AboutGbi>
      </FocusLock>
    </>
  )
}

export default About
