import React, { useContext } from "react"; 
import Modal from 'react-modal';
import { MainContext } from "../../helpers/MainContext";
import ExitModalButton from "../forms/ExitModalButton";
import LoadingIcon from "../forms/LoadingIcon";



import { Map, ZoomControl, Placemark } from 'react-yandex-maps';

import kpp1icon from '../../images/kpp1.svg';
import kpp3icon from '../../images/kpp3.svg';

const routeToKPP1 = "https://maps.yandex.ru/?rtext=~55.727050,37.236009&rtt=mt"
const routeToKPP3 = "https://maps.yandex.ru/?rtext=~55.724535,37.213054&rtt=mt"


const LocationModal = () => {
    const {locationVisibility, setLocationVisibility, setContactsVisibility} = useContext(MainContext);

    const closeModal = () => {
        if (typeof(document.getElementsByClassName("imageGallery")[0]) != 'undefined' && document.getElementsByClassName("imageGallery")[0] != null) {
            document.getElementsByClassName("imageGallery")[0].style.visibility = "visible";
        }
        setLocationVisibility(false)
    }

    const showContacts = () => {
        setContactsVisibility(true)
        setTimeout(() => {setLocationVisibility(false)}, 400);
    }

    const showMap = () => {
        let item = document.getElementById("mapRevealer");
        let loader = document.getElementById("loading-icon");

        if (item.classList.contains("useless") === true || loader.classList.contains("useless") === true ) {
            return
        } else {
            item.classList.add("useless")
            loader.classList.add("useless")
        }

    }


    Modal.setAppElement(`#___gatsby`);

    return (
        <Modal 
        isOpen={locationVisibility}
        onRequestClose={closeModal}
        className="Modal location"
        overlayClassName="Overlay"
        >
            <ExitModalButton closeFunction={closeModal} ariaLabel="Закрыть окно с адресом посёлка" />
            <h2 className="hidden">Расположение посёлка</h2>
            <address>
                <a id="addressLink" target="_blank" rel="noreferrer" href="https://yandex.ru/maps/-/CCUeq8ev8D">
                    МO, г. Одинцово, д. Жуковка, <br/>территория Ландшафт
                </a>
            </address>
            <div id="mapRevealer"></div>
            
            <div id="mapPlaceholder">
                <LoadingIcon ariaLabel="карту яндекс отгружает" />
                <Map 
                    className="map"
                    onLoad={showMap}
                    defaultState={
                        { 
                            center: [55.725632, 37.229979], 
                            zoom: 13,
                            controls: [],
                        }
                    }
                    instanceRef={ref => {
                        if (ref) {
                        ref.events.add("click", e => {
                            ref.balloon.close();
                        });
                        }
                    }}  
                >
                    <ZoomControl 
                        options={{
                            position: {
                                "right": "1rem",
                                "bottom": "2rem"
                            },
                            size: "small",
                        }}
                    />
                    <Placemark
                        modules={["geoObject.addon.balloon", "layout.Image" ]}
                        defaultGeometry={[55.724535, 37.213054]} // КПП3
                        defaultProperties={{
                            balloonContentHeader: `<a href=${routeToKPP3} target="_blank" rel="noreferrer"><h3 class="balloonContentHeader">КПП III</h3></a>`,
                            balloonContentBody: '<p class="balloonContentBody">Усово, Горки, Одинцово<p>',
                        }}
                        defaultOptions={{
                            iconLayout: 'default#image',
                            iconImageHref: kpp3icon,
                            iconImageSize: [30, 42],
                            iconImageOffset: [-15, -21],
                            balloonCloseButton: false,
                        }}
                    />
                    <Placemark
                        modules={["geoObject.addon.balloon"]}
                        defaultGeometry={[55.727050, 37.236009]} // КПП1
                        defaultProperties={{
                            balloonContentHeader: `<a href=${routeToKPP1} target="_blank" rel="noreferrer"><h3 class="balloonContentHeader">КПП I</h3><a>`,
                            balloonContentBody: '<p class="balloonContentBody">Жуковка, Барвиха, Москва<p>',
                        }}
                        defaultOptions={{
                            iconLayout: 'default#image',
                            iconImageHref: kpp1icon,
                            iconImageSize: [30, 42],
                            iconImageOffset: [-15, -21],
                            balloonCloseButton: false,
                        }}
                    />
                </Map>
            </div>
            {/* <p className="restrictedAccess">Доступ на территорию ограничен</p> */}
            <p className="restrictedAccess">проезд строго по пропускам</p>
            <button className="button showContacts" onClick={showContacts}>Контакты администрации</button>
            <div style={{
                display: "block",
                height: "1.5rem",
                witdh: "100%",
                flexShrink: 0,
            }}></div>
        </Modal>
    );
}

export default LocationModal;
