import { graphql, useStaticQuery } from "gatsby"

const ShortDataOnLands = () => {
  const landsData = useStaticQuery(graphql`
    query LandsQuery {
      allSanityLand(
        sort: { fields: orderPriority, order: DESC }
      ) {
        edges {
          node {
            _id
            name
            orderPriority
            currencyOfSell
            priceOnDemand
            priceToSell
            siteArea
            siteStatus
            siteResources
            imgFront {
              ...ImageWithPreview
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)
  return landsData
}

export default ShortDataOnLands
