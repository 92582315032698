// console warning possibly can be fixed in this issue https://github.com/timhagn/gatsby-background-image/issues/154

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import PropTypes from "prop-types";

const BgImageMemoized = React.memo( ({pluginImage, children}) => {
  return <>
        <BgImage key="AboutBgImage" image={pluginImage} loading="eager" className='BgImage-aboutSection' id="aboutSection">
          { children }
        </BgImage> 
  </>
})

const AboutGbi = ({ children }) => {
    const { placeholderImage } = useStaticQuery (
        graphql `
        query {
          placeholderImage: file(relativePath: { eq: "mansions.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "#fbfbfb"
                layout: CONSTRAINED
                outputPixelDensities: [0.6, 0.7, 0.8, 1, 2]
                placeholder: BLURRED
                blurredOptions: {width: 40}
                quality: 80
                webpOptions: {quality: 80}
              )
            }
          }
        }
      `
    )

    const pluginImage = getImage(placeholderImage);
    
    return (
      <BgImageMemoized pluginImage={pluginImage} children={children}/>
    );
} 

AboutGbi.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AboutGbi;