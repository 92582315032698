import React, {useState, useEffect, useRef} from 'react';

import fscreen from 'fscreen';
import { NextButton, PreviousButton, GoFullscreenButton, ExitFullscreenButton, QuestionButton, ZoomButton } from "../controls/galleryControls"
import ExitModalButton from '../forms/ExitModalButton'
import { ImageMemoized } from '../ImageMemoized';
import { eagerNeightborsLoading } from '../../helpers/littleHelpers'

import SwiperCore, { Controller, Thumbs, Navigation, Keyboard, Zoom  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/zoom/zoom.scss';
import 'swiper/components/controller/controller.scss';

SwiperCore.use([Controller, Navigation, Thumbs, Keyboard, Zoom]);


const Photosets = ({visible = false, exitFunction, photoData}) => {
    const [sectionShown, setSectionShown] = useState(visible);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [mobileFullscreen, setMobileFullscreen] = useState("");
    const [questionCalled, setQuestionCalled] = useState(false);
    const [photoDetailsBlockIsVoid, setPhotoDetailsBlockIsVoid] = useState(false);
    const [activeSlideData, setActiveSlideData] = useState({
        coordinate: null,
        description: null,
        width: null,
    });
    const [swiperZoomed, setSwiperZoomed] = useState(false);
    const maximizableElement = useRef(null);
    const swiperRef = useRef(null);
    const controlsRef = useRef(null);

    useEffect(() => {
        setSectionShown(visible);
    }, [visible]);

    useEffect(() => {
        swiperRef.current.swiper.updateSize();
    }, [isFullscreen]);


    fscreen.onfullscreenchange = fullscreenChangeStateHandler;
    function fullscreenChangeStateHandler() {

        if (fscreen.fullscreenElement !== null) {
            setIsFullscreen(true)
        } else {
            setIsFullscreen(false)
        }

    }
    
    const toggleZenMode = () => {
        const galleryObject = maximizableElement.current;
        setSwiperZoomed(false);

        if (fscreen.fullscreenEnabled === false ) {
            isFullscreen === true ? fscreen.exitFullscreen() : fscreen.requestFullscreen(galleryObject);
        } else {

            if (isFullscreen === false) {
                setMobileFullscreen("mobile");
                setIsFullscreen(true);
                window.setTimeout(() => {document.activeElement.blur()}, 100)
                zoomToggle(); //this somehow debugs mobile layout shift on fullscreen mode, - the only solution I found
            } else {
                setMobileFullscreen("");
                setIsFullscreen(false);
                window.setTimeout(() => {document.activeElement.blur()}, 100)
                zoomToggle();
            }
        }

    }

    const renderActiveImageDetails = (swiper) => {
        if (photoData) {
            
            const activeSlideInsides = swiper.slides[swiper.activeIndex].children[0].children;
            const activeSlide = activeSlideInsides[activeSlideInsides.length - 1];
            const activeSlideData = photoData.filter( e => e._key === activeSlide.id.replace(/-.+/, "") )

            const activeSlideAspectRatio = activeSlideData[0].asset.metadata.dimensions.aspectRatio;

            const mainImageHeight = getComputedStyle(maximizableElement.current).height


            const coordinate = activeSlide.getAttribute("coordinate");
            const description = activeSlide.getAttribute("description");
            const width = `${mainImageHeight.replace(/px/, "") * activeSlideAspectRatio}px`;

            
            
            setActiveSlideData({
                coordinate: coordinate,
                description: description,
                width: width,
            })
        }
            
    }

    const getImageWidth = ( image ) => {
        const activeSlideAspectRatio = image.asset.metadata.dimensions.aspectRatio;
        const mainImageHeight = getComputedStyle(maximizableElement.current).height;
        const width = `calc(${mainImageHeight} * ${activeSlideAspectRatio})`;

        return width;
    }
        
    const constructYandexMapLink = (coordinates) => {
        // yandex maps url api: 
        // https://yandex.ru/dev/yandex-apps-launch/maps/doc/concepts/yandexmaps-web.html
        let latitude = coordinates.replace(/^(.+,\s)/gm, "");
        let longitude = coordinates.replace(/(,\s.+)$/gm, "");
        return `https://yandex.ru/maps/?ll=${latitude},${longitude}&mode=routes&rtext=55.725609,37.230016~${longitude},${latitude}&rtt=auto&ruri=~&z=16&l=map`
    }

    const toggleInnerPhotoDetails = () => {
        setPhotoDetailsBlockIsVoid(false);
        setQuestionCalled(!questionCalled);
    }
    
    const unmountInnerPhotoDetails = () => {
        if (photoDetailsBlockIsVoid !== true) {
            setPhotoDetailsBlockIsVoid(true)
        } 
        if (questionCalled === true) {
            setQuestionCalled(false)
        }
    }

    const releaseMobileFocus = () => {
        window.matchMedia("not (pointer: fine)").matches && window.setTimeout(() => {document.activeElement.blur()}, 450)
    }

    const setControlsContainerSpotted = (swiperInstance) => {
        if ( controlsRef.current !== null && swiperInstance.realIndex !== 1 ) {
            controlsRef.current.classList.add("spotted")
        }
    }

    const onZoomChange = () => {
        if (swiperRef.current.swiper.zoom.currentScale !== 1) {
            setSwiperZoomed(false)
        } else {
            setSwiperZoomed(true)
        }
    }

    const zoomToggle = () => {
        setSwiperZoomed(!swiperZoomed);
        swiperRef.current.swiper.zoom.toggle()
        window.setTimeout(() => {document.activeElement.blur()}, 50)
    }

    const nextSlide = () => {
        setSwiperZoomed(false)
        swiperRef.current.swiper.zoom.out();
        window.setTimeout(() => {swiperRef.current.swiper.slideNext(450, true)}, 150)
    }

    const prevSlide = () => {
        setSwiperZoomed(false)
        swiperRef.current.swiper.zoom.out();
        window.setTimeout(() => {swiperRef.current.swiper.slidePrev(450, true)}, 150)
    }
    
    return (
        <>
            <section id="about-section-photosets" className={sectionShown ? "visible" : ""}>
                <h3 className="hidden">Фотографии из посёлка и округи</h3>
                <ExitModalButton closeFunction={exitFunction} ariaLabel="Закрыть окно подробностей о жизни посёлка" addClassName={`${isFullscreen === true ? "invisible" : ""}`} addSVGClassName={`aboutCloseSVG`} />
                <div 
                ref={maximizableElement}
                className={`imageGallery ${isFullscreen ? "fullscreen" : "default"} ${mobileFullscreen}`}
                >
                    <Swiper 
                        key="mainSwiperArea"
                        ref={swiperRef}
                        resizeObserver={true}
                        spaceBetween={16}
                        slidesPerView={1}
                        initialSlide={1}
                        grabCursor={true}
                        observeParents={true}
                        observer={true} 
                        allowSlidePrev={!swiperZoomed}
                        allowSlideNext={!swiperZoomed}
                        keyboard={{
                            enabled: true,
                            onlyInViewport: false,
                        }}
                        zoom={{
                            enabled: true,
                            maxRatio: 2.3,
                        }}
                        onResize={() => {swiperRef.current !== null && swiperRef.current.swiper.updateSize()}}
                        onZoomChange={onZoomChange}
                        loop={true}
                        speed={450}
                        onSlideChange={(swiper) => {
                            renderActiveImageDetails(swiper);
                            unmountInnerPhotoDetails();
                            eagerNeightborsLoading(swiper);
                            releaseMobileFocus();
                            setControlsContainerSpotted(swiper); 
                        }}
                        onInit={(swiper) => {renderActiveImageDetails(swiper)}}
                        containerModifierClass="big-image-"
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {
                            photoData !== undefined &&
                            photoData.map((image, index) => (
                                <SwiperSlide key={`${index}-main`}>
                                    <div className="swiper-zoom-container">
                                        <ImageMemoized 
                                            image={image} 
                                            index={index} 
                                            imagesCount={photoData.length} 
                                            targetWidth={2560} 
                                            styleWidth={getImageWidth(image)}
                                            // styleHeight={getImageHeight()}
                                        />
                                    </div>
                                    {
                                        (isFullscreen || mobileFullscreen === "mobile") && (activeSlideData.coordinate || activeSlideData.description) ?
                                            <div className={`inner-image-details-block${questionCalled ? " called" : ""}${photoDetailsBlockIsVoid === true ? " void" : ""}`}>
                                            {activeSlideData.description && 
                                                <p style={{maxWidth: `${activeSlideData.width}px`}}>
                                                    {activeSlideData.description}
                                                </p> 
                                            }
                                            {activeSlideData.coordinate && 
                                                <a href={constructYandexMapLink(activeSlideData.coordinate)} target="_blank" rel="noreferrer" style={{maxWidth: `${activeSlideData.width}px`}}>
                                                    {activeSlideData.coordinate}
                                                </a>
                                            }
                                            </div>
                                        :
                                            null
                                    }

                                </SwiperSlide>
                            ))
                        }
                        <div className="controls-container" ref={controlsRef}>
                            <ZoomButton passClassName={`zoom-button`} onClickFunction={zoomToggle} />
                            <PreviousButton passClassName="swiper-button-prev" ariaLabel="Предыдущее фото" onClickFunction={prevSlide} />
                            { isFullscreen === true ? ( 
                                <ExitFullscreenButton passClassName="fullscreenToggle" ariaLabel="Выйти из режима просмотра" onClickFunction={toggleZenMode} /> 
                                ) 
                            : (
                                <GoFullscreenButton passClassName="fullscreenToggle" ariaLabel="Перейти к просмотру" onClickFunction={toggleZenMode} />
                                ) 
                            }
                            <NextButton passClassName="swiper-button-next" ariaLabel="Следующее фото" onClickFunction={nextSlide} />
                            <QuestionButton     
                                passClassName={`question-button ${(isFullscreen === true || mobileFullscreen === "mobile") && (activeSlideData.coordinate || activeSlideData.description) ? "" : "invisible"}`} 
                                ariaLabel="Узнать что за место" 
                                onClickFunction={toggleInnerPhotoDetails} />
                        </div>
                    </Swiper>
                </div>
                <div className={`image-details-block ${isFullscreen === true || mobileFullscreen === "mobile" ? "fullscreen" : ""}`} >
                    {activeSlideData.description && <p style={{maxWidth: `${activeSlideData.width}`}}>{activeSlideData.description}</p> }
                    {activeSlideData.coordinate && 
                        <a href={constructYandexMapLink(activeSlideData.coordinate)} target="_blank" rel="noreferrer">
                            {activeSlideData.coordinate}
                        </a>
                    }
                </div>
            </section>
        </>
    )
}

export default Photosets;