import React, { useEffect, useContext, useRef } from "react";
import AvailableObjects from "./availableObjects"
import { MainContext } from "../../helpers/MainContext";
 
 const Objects = () => {
  const {filterObjectsBy, setFilterObjectsBy} = useContext(MainContext);
  const scrollTopDivider = useRef(null);

  useEffect(() => {
    let parent = document.getElementsByClassName("objectsWrapper")[0];
    let nodesToSort = parent.childNodes;
    
    const getPriority = (estateObject) => {
      let sortIndex = parseFloat(estateObject.getAttribute('index'))
      return sortIndex;
    }
    
    // Insertion sort for NodeList implementation
    for (let i = 1; i < nodesToSort.length; i++) {
      let j = i;
      while ( j > 0 && getPriority(nodesToSort[j]) > getPriority(nodesToSort[j - 1]) ) {
        parent.insertBefore( nodesToSort[j], nodesToSort[j - 1] );
        j--;
      }
    }

    if (filterObjectsBy === "onSale") {
      document.getElementById("saleOnly").checked = true;
      document.getElementById("landOnly").checked = false;
      document.getElementById("rentOnly").checked = false;
    } if (filterObjectsBy === "onRent") {
      document.getElementById("saleOnly").checked = false;
      document.getElementById("landOnly").checked = false;
      document.getElementById("rentOnly").checked = true;
    } if (filterObjectsBy === "onLand") {
      document.getElementById("saleOnly").checked = false;
      document.getElementById("landOnly").checked = true;
      document.getElementById("rentOnly").checked = false;
    }
  }, [filterObjectsBy]);


  const scrollToObjectsSection = () => {
    window.scroll(0, document.getElementsByClassName("objects")[0].offsetTop);
  }

  const saleSwitch = () => {
    if ( filterObjectsBy === "onRent" || filterObjectsBy === "onLand" ) {
      scrollToObjectsSection();
      setFilterObjectsBy("onSale");
    } else {
      scrollToObjectsSection();
      setFilterObjectsBy("onRent");
    }
  }

  const rentSwitch = () => {
    if ( filterObjectsBy === "onSale" || filterObjectsBy === "onLand" ) {
      scrollToObjectsSection();
      setFilterObjectsBy("onRent");
    } else {
      scrollToObjectsSection();
      setFilterObjectsBy("onSale");
    }
   }

   const landSwitch = () => {
    if ( filterObjectsBy === "onSale" || filterObjectsBy === "onRent" ) {
      scrollToObjectsSection();
      setFilterObjectsBy("onLand");
    } else {
      scrollToObjectsSection();
      setFilterObjectsBy("onSale");
    }
   }

   const scrollTop = () => {
    window.scrollTo(0, 0);
    document.activeElement.blur();
   }

   const handleKeypress = e => {
    if ( e.keyCode === 13 ) {
      scrollTop();
      document.activeElement.blur();
    }
  }

    return (
      <>
        <section className='objects' key="objectsSection">
          <div className="ios-divider-bg">
            <div className="divider-ornament" ref={scrollTopDivider} onKeyDown={handleKeypress} onClick={scrollTop} tabIndex={0} role="button" aria-label="Вернуться наверх страницы"></div>
          </div>
          <div id="hat-controls">
            <h2 id="objectsHeading">Привилегия,<br /> которую можно оценить</h2>
              <div className="optionsSwitchesContainer">
                <div className="optionBox">
                  <label htmlFor="saleOnly">Дома в продаже</label>
                  <input type="checkbox" className="switch" id="saleOnly" onChange={saleSwitch} />
                </div>
                <div className="optionBox">
                  <label htmlFor="rentOnly">Дома в аренду</label>
                  <input type="checkbox" className="switch" id="rentOnly" onChange={rentSwitch} />
                </div>
                <div className="optionBox">
                  <label htmlFor="landOnly"><span className="no-break">Участки в</span> продаже</label>
                  <input type="checkbox" className="switch" id="landOnly" onChange={landSwitch} />
                </div>
              </div>
          </div>
          <div className="objectsWrapper">
            <AvailableObjects filter={filterObjectsBy} />
          </div>
        </section>
      </>
    )
}

export default Objects;