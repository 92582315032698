import { graphql, useStaticQuery,  } from "gatsby"


export const GetPhotosetsData = () => {
  const photosetsData = useStaticQuery(graphql`
    query photosetsQuery {
      allSanityPhotoset {
        edges {
          node {
            id
            name
            photosetGallery {
              _key
              description
              coordinate
              ...ImageWithPreview 
              asset {
                metadata {
                  dimensions { 
                    aspectRatio
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return photosetsData
}

export const GetAllPhotos = () => {
    let photosetsData = GetPhotosetsData();
    const allPhotos = []; 
    let priorities = [];
    let reorderedPhotos = [];

    photosetsData.allSanityPhotoset.edges.map(edge => {
        let edgesName = edge.node.name;
        let edgesPriority = edge.node.priority;

        if ( !priorities.includes(edgesPriority) ) {
          priorities.push(edgesPriority)
        }

        edge.node.photosetGallery.forEach(photoObject => {
          photoObject.photoset = edgesName;
          photoObject.showorder = edgesPriority;
          allPhotos.push(photoObject);
        })
    })

    priorities.sort((a, b) => { return b - a } )

    for ( let i = 0; i < priorities.length; i++ ) {
      let samePriorityPhotos = allPhotos.filter( e => e.showorder === priorities[i] )
      reorderedPhotos = reorderedPhotos.concat(samePriorityPhotos);
    }

    return reorderedPhotos;

}
